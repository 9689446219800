import { gql } from '@apollo/client';

import { USE_CONTENT_PREVIEW_FILTER } from 'constants/query';
import { COMPONENT_FRAGMENTS, MODULE_FRAGMENTS } from 'queries/fragments';
import { FRAGMENTS } from './navigation';

const getDetailFilter = USE_CONTENT_PREVIEW_FILTER
  ? `{
          OR: [
            { slug: $pageSlug, navigationTag: { slug: $tagSlug } }
            { slug: $pageSlug, navigationTag: { sys: { id: $tagSlug } } }
          ]
        }`
  : `{ slug: $pageSlug, navigationTag: { slug: $tagSlug } }`;

// Note that while dynamic landing pages do not have field-level localization, we must pass $locale to gql for curated list to work correctly.
// A curated list can contain a global detail page, which requires $locale.
const PAGE_DETAIL_GLOBAL_TRENDS_QUERY = {
  body: ({
    collection = 'pageDynamicLandingCollection',
    filter = getDetailFilter,
  }) => gql`
    query DynamicLandingPage(
      $preview: Boolean = false
      $tagSlug: String!
      $pageSlug: String!
      $locale: String!
    ) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          meta {
            ...MetaFields
          }
          hero {
            ...HeroFieldsTrend
          }
          navigationTag {
            title
          }
          slug
          lowerNavigation {
            navigationItemsCollection {
              items {
                type: __typename
                ... on NavigationTag {
                  ...NavigationTag
                }
                ... on Dropdown {
                  ...Dropdown
                }
              }
            }
            isBackButton
            isBreadcrumb
            showLocaleSelector
          }
          backgroundColor
        }
      }
    }
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${COMPONENT_FRAGMENTS.META}
    ${COMPONENT_FRAGMENTS.TRENDS_HERO}
    ${FRAGMENTS.NAVIGATION_TAG}
    ${FRAGMENTS.LINK}
    ${FRAGMENTS.DROPDOWN}
  `,
  modules: ({
    collection = 'pageDynamicLandingCollection',
    filter = getDetailFilter,
  }) => gql`
    query GlobalDetailPage(
      $preview: Boolean = false
      $tagSlug: String!
      $pageSlug: String!
      $locale: String!
    ) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          modulesCollection {
            items {
              ... on ModuleTrendGrid {
                ...TrendGridWithTrends
              }
            }
          }
        }
      }
    }
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${COMPONENT_FRAGMENTS.STAT_CARD}
    ${MODULE_FRAGMENTS.QUOTE}
    ${MODULE_FRAGMENTS.FOOTNOTES}
    ${COMPONENT_FRAGMENTS.CTA_BANNER}
    ${COMPONENT_FRAGMENTS.TREND_CARD}
    ${COMPONENT_FRAGMENTS.CONTENT_TILE_WITH_TRENDS}
    ${MODULE_FRAGMENTS.TREND_GRID_WITH_TRENDS}
  `,
};

export default PAGE_DETAIL_GLOBAL_TRENDS_QUERY;
